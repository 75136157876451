import { template } from './template/template';

export const environment = {
  production: true,
  debug: false,
  useGo: true,
  lite: true,
  fast: true,
  isMobile: false,
  isLocal: false,
  template: template,
  apiUrl: 'https://api.schott.stage.valueminer.eu',
  messagingUrl: 'https://schott.stage.valueminer.eu/messaging',
  messagingSocketNamespace: 'https://schott.stage.valueminer.eu',
  messagingSocketPath: '/socket.io',
  exportUrl: 'https://export.schott.stage.valueminer.eu',
  oauthUrl: 'https://oauth.schott.stage.valueminer.eu',
  imageUrl: 'https://oauth.schott.stage.valueminer.eu/profile/?id=',
  fileupload: 'https://oauth.schott.stage.valueminer.eu',
  go: 'https://apigo.schott.stage.valueminer.eu',
  goPrefix: {},
  goSuffix: {},
  app: '',
  s3: 'valueminer.local',
  aws: {
    access_key_id: 'AKIAVQ3RHJYALBWTHAN4',
    access_key_secret: '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',
    s3_bucket: 'valueminer.local',
    s3_region: 'eu-central-1',
  },
  auth: {
    authurl: 'https://oauth.schott.stage.valueminer.eu/oauth/authorize/',
    tokenurl: 'https://oauth.schott.stage.valueminer.eu/oauth/token/',
    logouturl: 'https://oauth.schott.stage.valueminer.eu/wp-login.php?action=logout',
    authorization: {},
    clientID: 'valueminer',
    mobileClientId: 'mobile',
    redirectURL: 'https://schott.stage.valueminer.eu/',
    scopes: ['api', 'users']
  },
  socket: {
    url: 'https://apigo.schott.stage.valueminer.eu',
    path: '/socket.io/'
  },
  design: {
    key: '',
    logo: './assets/svg/logo.green.svg',
    title: 'ValueMiner',
    titleIsImage: false,
    subtitle: '',
    welcomeText: ''
  },
  roleSelection: {},
  mobileDesign: {}
};
